var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "appsumo-plan" }, [
    _c(
      "div",
      { staticClass: "price-list" },
      [
        _vm.appSumoPlans.length
          ? [
              _c("div", { staticClass: "price-list__plans" }, [
                _c(
                  "div",
                  { staticClass: "price-list__plans-list" },
                  _vm._l(_vm.appSumoPlans, function (plan) {
                    return _c(
                      "div",
                      {
                        key: plan.id,
                        staticClass: "price-list__plans-list__item",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "price-list__plans-list__item__heading",
                            class: {
                              "price-list__plans-list__item__heading--active":
                                _vm.CURRENT_PLAN === plan.id,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "price-list__plans-list__item__heading__title",
                              },
                              [_vm._v(_vm._s(plan.title))]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "price-list__plans-list__item__body" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__list__item__content__price",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pricing-plans__list__item__content__price__currency",
                                  },
                                  [_vm._v("$")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pricing-plans__list__item__content__price__amount",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.planPrice(plan)) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "price-list__plans-list__item__heading__cost",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t(plan.users)) +
                                    " " +
                                    _vm._s(_vm.$t("appsumo.users"))
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "price-list__plans-list__item__heading__label-action",
                                class: _vm.SET_PLAN_CLASS(
                                  plan,
                                  "heading-label"
                                ),
                              },
                              [
                                _vm.appsumo.invoice_item_uuid
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "price-list__plans-list__item__heading__label-action__text",
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeAppSumoPlan(
                                              _vm.CURRENT_PLAN === plan.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm.CURRENT_PLAN === plan.id
                                          ? [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("pricing.currentPlan")
                                                )
                                              ),
                                            ]
                                          : [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.planChangeText(plan)
                                                  )
                                                ) + " "
                                              ),
                                            ],
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "price-list__plans-list__item__body__options-list",
                              },
                              _vm._l(plan.options, function (option) {
                                return _c(
                                  "li",
                                  {
                                    key: option.id,
                                    staticClass:
                                      "price-list__plans-list__item__body__options-list__item",
                                  },
                                  [
                                    _c("vs-icon", {
                                      staticClass:
                                        "price-list__plans-list__item__body__options-list__item__icon",
                                      attrs: { size: "20px", icon: "check" },
                                    }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          option.description
                                            ? _vm.$t(option.description)
                                            : ""
                                        ) +
                                        "\n                "
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }