<template>
  <div class="appsumo-plan">
    <div class="price-list">
      <template v-if="appSumoPlans.length">
        <div class="price-list__plans">
          <div class="price-list__plans-list">
            <div v-for="plan in appSumoPlans" class="price-list__plans-list__item" :key="plan.id">
              <div
                class="price-list__plans-list__item__heading"
                :class="{
                  'price-list__plans-list__item__heading--active': CURRENT_PLAN === plan.id
                }"
              >
                <div class="price-list__plans-list__item__heading__title">{{ plan.title }}</div>
              </div>

              <div class="price-list__plans-list__item__body">
                <div class="pricing-plans__list__item__content__price">
                  <div class="pricing-plans__list__item__content__price__currency">$</div>
                  <div class="pricing-plans__list__item__content__price__amount">
                    {{ planPrice(plan) }}
                  </div>
                </div>

                <div class="price-list__plans-list__item__heading__cost">{{ $t(plan.users) }} {{ $t('appsumo.users') }}</div>

                <div class="price-list__plans-list__item__heading__label-action" :class="SET_PLAN_CLASS(plan, 'heading-label')">
                  <div
                    v-if="appsumo.invoice_item_uuid"
                    class="price-list__plans-list__item__heading__label-action__text"
                    @click="changeAppSumoPlan(CURRENT_PLAN === plan.id)"
                  >
                    <template v-if="CURRENT_PLAN === plan.id">{{ $t('pricing.currentPlan') }}</template>
                    <template v-else>{{ $t(planChangeText(plan)) }} </template>
                  </div>
                </div>
                <ul class="price-list__plans-list__item__body__options-list">
                  <li v-for="option in plan.options" class="price-list__plans-list__item__body__options-list__item" :key="option.id">
                    <vs-icon size="20px" icon="check" class="price-list__plans-list__item__body__options-list__item__icon" />
                    {{ option.description ? $t(option.description) : '' }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { appsumo_subscription } from '@/mixins/appsumo_subscription'

export default {
  mixins: [appsumo_subscription],

  data() {
    return {
      appsumo: null
    }
  },

  async beforeMount() {
    if (this.company && this.company.isAppSumoAccount && this.company.appsumoUUID) {
      const appsumoRef = await this.$db.collection('appsumo-accounts').doc(this.company.appsumoUUID).get()
      if (appsumoRef && appsumoRef.data()) {
        this.appsumo = appsumoRef.data()
      }
    }
  },

  watch: {
    async 'company.id'() {
      if (this.company && this.company.isAppSumoAccount && this.company.appsumoUUID) {
        const appsumoRef = await this.$db.collection('appsumo-accounts').doc(this.company.appsumoUUID).get()
        if (appsumoRef && appsumoRef.data()) {
          this.appsumo = appsumoRef.data()
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      appSumoSubscriptionRefunded: 'appSumoSubscriptionRefunded'
    }),

    CURRENT_PLAN() {
      const plan = this.company && this.company.subscriptionPlanId ? this.company.subscriptionPlanId : null
      return plan
    },

    CURRENT_PLAN_LEVEL() {
      const planId = this.company && this.company.subscriptionPlanId ? this.company.subscriptionPlanId : null
      const currentPlan = this.appSumoPlans.find((x) => x.id === planId)
      return currentPlan && currentPlan.level ? currentPlan.level : null
    }
  },

  methods: {
    SET_PLAN_CLASS(plan, position) {
      if (position === 'heading-label') {
        if (this.CURRENT_PLAN === plan.id) {
          return 'price-list__plans-list__item__heading__label-action_active'
        }
        return ''
      }
    },

    planChangeText(plan) {
      if (!this.CURRENT_PLAN_LEVEL) {
        return 'vue.choosePlan'
      }
      return plan.level < this.CURRENT_PLAN_LEVEL ? 'pricing.downgrade' : 'pricing.upgradeNow'
    },
    planPrice(plan) {
      return plan.price
    },
    changeAppSumoPlan(isCurrentPlan) {
      if (isCurrentPlan) return

      if (this.appSumoSubscriptionRefunded) {
        window.open('https://appsumo.com/products/lets-connect/', '_blank').focus()
      } else {
        window.open(`https://appsumo.com/account/redemption/${this.appsumo.invoice_item_uuid}#change-plan`, '_blank').focus()
      }
    }
  }
}
</script>
<style lang="scss">
@import './myplan.scss';
</style>
